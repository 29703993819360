<template>
  <div class="ui-modal-title">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiModalTitle',
};
</script>

<style lang="scss">
.ui-modal-title {
  font-size: 1rem;
  font-weight: $font-weight__heavier;
  line-height: 1;
  margin-bottom: 16px;

  @media #{$mq_screen__m} {
    font-size: 1.25rem;
  }

  @media #{$mq_screen__l} {
    font-size: 1.5rem;
  }
}
</style>
