<template>
  <UiModal
    v-if="hasCookiesConfig"
    class="cookie-consent-modal"
    :is-open="shouldShowModal"
    :disable-close="true"
    :use-status-loader="false"
  >
    <template #body>
      <div class="content-wrapper">
        <div class="cookie-information">
          <UiModalTitle
            v-if="title"
            class="title"
          >
            {{ title }}
          </UiModalTitle>
          <div
            v-if="text || link.href"
            class="text"
          >
            {{ text }}
            <UiLink
              v-if="link.href"
              :link="link.href"
            >
              {{ link.text }}
            </UiLink>
          </div>
        </div>
        <div class="cookie-actions">
          <UiButton
            class="cookie-reject cookie-action"
            contrast="light"
            :link="reject"
          >
            {{ $t('cookies.reject') }}
          </UiButton>
          <UiButton
            class="cookie-accept cookie-action"
            :link="accept"
            contrast="light"
          >
            {{ $t('cookies.accept') }}
          </UiButton>
        </div>
      </div>
    </template>
  </UiModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UiModal from '@/components/ui/uiModal/UiModal.vue';
import UiLink from '@/components/ui/uiLink/UiLink.vue';
import UiButton from '@/components/ui/uiButton/UiButton.vue';
import UiModalTitle from '@/components/ui/uiModal/UiModalTitle.vue';

export default {
  name: 'CookieConsent',
  components: {
    UiModalTitle,
    UiButton,
    UiLink,
    UiModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'shouldShowCookieConsent',
      'hasCookiesConfig',
      'cookiesConfig',
    ]),
    title() {
      return this.cookiesConfig.title;
    },
    text() {
      return this.cookiesConfig.text;
    },
    link() {
      return this.cookiesConfig.link || {};
    },
    shouldShowModal() {
      return this.showModal && this.shouldShowCookieConsent;
    },
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 0);
  },
  methods: {
    ...mapActions([
      'acceptCookies',
      'rejectCookies',
    ]),
    accept() {
      this.acceptCookies();
      this.closeModal();
    },
    reject() {
      this.rejectCookies();
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.cookie-consent-modal {
  z-index: 99999999999;

  .modal-container {
    background: rgba(0,0,0,0.9);
    color: #fff
  }

  .body-gradiant {
    display: none;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .ui-link {
    color: $color-white;
    font-weight: $font-weight__heavier;
  }

  .cookie-information {
    & > div {
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .title {
      font-weight: $font-weight__semibold;
    }
  }

  .cookie-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: -8px -10px;

    .cookie-action {
      margin: 8px 10px;
      min-width: auto;
      width: auto;
      flex: 1 0 auto;
      max-width: calc(100% - 20px);
    }
  }
}
</style>

<style lang="scss" theme="cupra">
.cookie-consent-modal {
  .modal-container {
    background-color: $brand-tertiary;
  }
}
</style>

<style lang="scss" theme="carbuy">
.cookie-consent {
  .ui-link {
    &:hover {
      color: $color-white;
      opacity: .8;
    }
  }
}
</style>
